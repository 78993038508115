"use strict";
$(function () {
    // allow only number
    $(document).on('keypress', '.allow-only-number', function () {
        $(".allow-only-number").attr("min", "10");
            var valueLength = this.value.length;
            if (valueLength == 10) {
                return false;
            }
            var kk = e.which;
            if (kk < 48 || kk > 57)
                e.preventDefault();
    });

    setTimeout(function () {
        $('.toast-top-right').fadeOut('fast');
    }, 30000); // <-- time in milliseconds

    $(document).on('click', '.close-modal', function () {
        location.reload();
    });
    // Call the dataTables jQuery plugin
    $('#dataTable').DataTable({
        responsive: true,
    });

    // Call the datetimepicker plugin
    var year = (new Date).getFullYear();
    $('.datetimepicker').datetimepicker({
        format: 'YYYY-MM-DD',
        minDate: new Date(year, 0, 1),
        maxDate: new Date(year + 1, 11, 31),
    });
    //adding datetimepicker for DOB
    $('.dobpicker').datetimepicker({
        format: 'YYYY-MM-DD',
    });

    //tinymce editor
    if ($(".ele1").length > 0) {
        tinymce.init({
            selector: "textarea.ele1",
            content_style: ".mce-content-body {font-size:20px;font-family:Arial,sans-serif;}",
            theme: "modern",
            height: 200,
            fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
        });
    }
});
//one click open close sidebar
$(document).on('click', '.hamburger--elastic', function () {
    $(this).toggleClass('is-active')
    $('.fixed-sidebar').toggleClass('closed-sidebar').toggleClass('sidebar-mobile-open');
});

$(document).on('click', '.mobile-toggle-header-nav', function () {
    $('.app-header__content').toggleClass('header-mobile-open');
});

//theme's js
$(function () {
    $(document).on('click', 'switch-sidebar-cs-class', function () {
        $(this).addClass('active');
        $(this).siblings().removeClass('active');
        var clickedVal = $(this).attr('data-class');
        $("#config_color_scheme_class").val(clickedVal);
        //header color scheme
        var exisitingHeaderClass = $("#main-header").attr('class').split(' ').pop();
        $("#main-header").removeClass(exisitingHeaderClass).addClass(clickedVal);
        //sidebar color scheme
        var exisitingSidebarClass = $("#main-sidebar").attr('class').split(' ').pop();
        $("#main-sidebar").removeClass(exisitingSidebarClass).addClass(clickedVal);
    })
});

//footer fixed js
$(function () {
    //fixed footer
    $(document).on('change', '#config_is_footer_fixed', function () {
        if ($('#config_is_footer_fixed').is(":checked")) {
            $(".app-container").addClass('fixed-footer');
        } else {
            $(".app-container").removeClass('fixed-footer');
        }
    });

    //fixed sidebar
    $(document).on('change', '#config_is_sidebar_fixed', function () {
        if ($('#config_is_sidebar_fixed').is(":checked")) {
            $(".app-container").addClass('fixed-sidebar');
        } else {
            $(".app-container").removeClass('fixed-sidebar');
        }
    });

    //fixed header
    $(document).on('change', '#config_is_header_fixed', function () {
        if ($('#config_is_header_fixed').is(":checked")) {
            $(".app-container").addClass('fixed-header');
        } else {
            $(".app-container").removeClass('fixed-header');
        }
    });
});


//toot-tip
$(function () {
    $('[data-toggle="tooltip"]').tooltip()
});

//select2
$(function () {
   $(".search-dropdown").select2({ width: '100%', });
});

//function to clone employeement history
$(document).on('click', '.add-more', function () {
    var $tr = $(this).closest('.tr_clone');
    var $clone = $tr.clone();
    $clone.find('.copy-row').val('');
    $tr.after($clone);
    $('.dobpicker').datetimepicker({
        format: 'YYYY-MM-DD',
    });
});

//function to remove clone element
$("body").on("click", ".remove", function () {
    $(this).parents(".after-add-more").remove();
});

//Js for password confirmation
$('#password, #password_confirmation').on('keyup', function () {
    if ($('#password').val() == $('#password_confirmation').val()) {
        $('#message').html('Matched').css('color', 'green');
    } else
        $('#message').html('Not Matched').css('color', 'red');
});

//employee salary calculation
$(function(){
    $(document).on('click','.gross-salary',function(){
        var calculated_total_earning = 0;
        $("#employeeEarning .txtCal").each(function () {
            var get_textbox_earning_value = $(this).val();
            if ($.isNumeric(get_textbox_earning_value)) {
                calculated_total_earning += parseFloat(get_textbox_earning_value);
            }
        });
        var calculated_total_deduction = 0;
        $("#employeeDeduction .txtCal").each(function () {
            var get_textbox_deduction_value = $(this).val();
            if ($.isNumeric(get_textbox_deduction_value)) {
                calculated_total_deduction += parseFloat(get_textbox_deduction_value);
            }
        });

        $(".total-salary").removeClass("d-none");
        var grossSal = (calculated_total_earning -calculated_total_deduction);
        $('#gross-salary').val(grossSal);
    });
});

//instialization of select2
$(function() {
    $(".search-dropdown").select2({ 
        width: '100%', 
        placeholder: "Select an Option", 
        allowClear: true
        // tags: true,
    });
})

// copy the current address
$(document).on('click', '.copy-current-address', function () {
    if ($('.copy-current-address').is(":checked")) {
        var currentAddress = $('#current_address').val();
        $('#permanent_address').val(currentAddress);
    } else {
        $('#permanent_address').val('');
    }
});
